export const CONTENT_TYPES = {
  adapter1111: '/component/1-1-1-1',
  adapter121: '/component/1-2-1',
  adapter13: '/component/1-3',
  adapter1up: '/component/1-up',
  adapter22: '/component/2-2',
  adapter31: '/component/3-1',
  document: '/component/document',
  facebookvideo: '/component/facebookvideo',
  flexcontent: '/component/flexcontent',
  image: '/component/image',
  instagramvideo: '/component/instagramvideo',
  simplelist: '/component/simplelist',
  specialnote: '/component/specialnote',
  textblock: '/component/textblock',
  tiktokvideo: '/component/tiktokvideo',
  video: '/component/video',
  vimeovideo: '/component/vimeovideo',
  wistiavideo: '/component/wistiavideo',
  youtubevideo: '/component/youtubevideo',
} as const;
