import { ElementType, lazy } from 'react';

import { CONTENT_TYPES } from '~/common/content-types';

export const DEFAULT_CONTENT_TYPE_COMPONENT_MAP: Record<string, ElementType> = {
  [CONTENT_TYPES.image]: lazy(async () => {
    return {
      default: (await import('./Image/Image')).Image,
    };
  }),
  [CONTENT_TYPES.specialnote]: lazy(async () => {
    return {
      default: (await import('./SpecialNote/SpecialNote')).SpecialNote,
    };
  }),
  [CONTENT_TYPES.textblock]: lazy(async () => {
    return {
      default: (await import('./TextBlock/TextBlock')).TextBlock,
    };
  }),
  [CONTENT_TYPES.simplelist]: lazy(async () => {
    return {
      default: (await import('./SimpleList/SimpleList')).SimpleList,
    };
  }),
  [CONTENT_TYPES.youtubevideo]: lazy(async () => {
    return {
      default: (await import('./YoutubeVideo/YoutubeVideo')).YoutubeVideo,
    };
  }),
  [CONTENT_TYPES.wistiavideo]: lazy(async () => {
    return {
      default: (await import('./WistiaVideo/WistiaVideo')).WistiaVideo,
    };
  }),
  [CONTENT_TYPES.instagramvideo]: lazy(async () => {
    return {
      default: (await import('./InstagramVideo/InstagramVideo')).InstagramVideo,
    };
  }),
  [CONTENT_TYPES.facebookvideo]: lazy(async () => {
    return {
      default: (await import('./FacebookVideo/FacebookVideo')).FacebookVideo,
    };
  }),
  [CONTENT_TYPES.tiktokvideo]: lazy(async () => {
    return {
      default: (await import('./TikTokVideo/TikTokVideo')).TikTokVideo,
    };
  }),
  [CONTENT_TYPES.vimeovideo]: lazy(async () => {
    return {
      default: (await import('./VimeoVideo/VimeoVideo')).VimeoVideo,
    };
  }),
  [CONTENT_TYPES.video]: lazy(async () => {
    return {
      default: (await import('./Video/Video')).Video,
    };
  }),
  [CONTENT_TYPES.document]: lazy(async () => {
    return {
      default: (await import('./Document/Document')).Document,
    };
  }),
  [CONTENT_TYPES.adapter1up]: lazy(async () => {
    return {
      default: (await import('./Adapters/Adapters')).Adapter1up,
    };
  }),
};
