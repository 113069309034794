import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useCMSContext } from '@encoura-internal/cms-toolkit/client/context';
import React from 'react';

const MINUTE = 60 * 1000;

const createQueryClient = (isCachingEnabled = true) => {
  /**
   * CMS want the cache to be disabled when in authoring mode.
   * setting the `gcTime` and `staleTime` to 0 means that any data is considered
   * stale right after fetch and the cache will be garbage collected right away - essentially disabling the cache
   * */
  return new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: isCachingEnabled ? 15 * MINUTE : 0,
        staleTime: isCachingEnabled ? 10 * MINUTE : 0,
      },
    },
  });
};

// eslint-disable-next-line import/prefer-default-export
export const RestQueryClientProviderComponent: React.FC<React.PropsWithChildren> = ({ children }) => {
  const cmsContext = useCMSContext();

  // const queryClient = createQueryClient(!cmsContext.xb.isAuthoring);
  const queryClient = React.useMemo(() => createQueryClient(!cmsContext.xb.isAuthoring), [cmsContext.xb.isAuthoring]);

  return React.useMemo(() => <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>, [children, queryClient]);
};
