import { RenderField } from '@craftercms/experience-builder/react';
import { Stack, SxProps } from '@mui/material';
import { useEffect } from 'react';

import { CrafterInstagramVideo } from '~/types/models/instagram-video';

export type InstagramVideoProps = {
  model: CrafterInstagramVideo;
  sx?: SxProps;
};

export const InstagramVideo = ({ model, sx }: InstagramVideoProps): React.ReactNode => {
  const { url_t: url } = model;

  useEffect(() => {
    if (!window.instgrm) {
      const script = document.createElement('script');
      script.src = 'https://www.instagram.com/embed.js';
      script.async = true;
      script.onload = (): void => {
        if (window.instgrm) {
          window.instgrm.Embeds.process();
        }
      };
      document.body.appendChild(script);
    } else {
      window.instgrm.Embeds.process();
    }
  }, []);

  return (
    <Stack
      sx={{
        aspectRatio: { sm: '16/9', xs: '4/3' },
        ...(sx ?? {}),
      }}
    >
      <RenderField fieldId="title_t" model={model} />
      <blockquote
        aria-label={`Instagram video post, URL: ${url}`} // Descrição para leitores de tela
        className="instagram-media"
        data-instgrm-permalink={url}
        data-instgrm-version="12"
        data-testid="instagram-video"
      />
      <RenderField fieldId="description_t" model={model} />
    </Stack>
  );
};
