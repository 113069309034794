'use client';

import { RenderComponents } from '@craftercms/experience-builder/react';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { useCMSContext } from '~/client/context/provider';
import { CrafterAdapter1up } from '~/types/models/adapters';
import { buildCrafterKeyGenerator } from '~/utils/build-crafter-key-generator';

export type Adapter1upProps<TSlot> = {
  model: CrafterAdapter1up<TSlot>;
  sx?: SxProps;
};

const sharedProps = {
  componentProps: {
    style: {
      minHeight: 40,
      width: '100%',
    },
  },
  itemKeyGenerator: buildCrafterKeyGenerator,
} as const;

export const Adapter1up = <TSlot = unknown,>({ model, sx = {} }: Adapter1upProps<TSlot>): React.ReactNode => {
  const ctx = useCMSContext();

  if (!model?.slot1_o) return null;

  return (
    <Box sx={{ display: 'grid', gap: 3, gridTemplateColumns: '1fr', ...sx }}>
      <Grid item>
        <RenderComponents contentTypeMap={ctx.client.contentTypeMap} fieldId="slot1_o" model={model} {...sharedProps} />
      </Grid>
    </Box>
  );
};
