import { RenderComponentsProps } from '@craftercms/experience-builder/react';

type ItemKeyGeneratorProps = NonNullable<RenderComponentsProps['itemKeyGenerator']>;

export const buildCrafterKeyGenerator: ItemKeyGeneratorProps = (
  contentInstance,
  index,
  collection,
): string | number => {
  return `${contentInstance.craftercms.id}_${index}_of_${collection.length}`;
};
