import { RenderField } from '@craftercms/experience-builder/react';
import { Stack, SxProps } from '@mui/material';
import { useEffect, useRef } from 'react';

import { CrafterTikTokVideo } from '~/types/models/tiktok-video';

export type TikTokVideoProps = {
  model: CrafterTikTokVideo;
  sx?: SxProps;
};

export const TikTokVideo = ({ model, sx }: TikTokVideoProps): React.ReactNode => {
  const { url_t: url, title_t: title } = model;
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    if (iframeRef.current) {
      const embedUrl = `https://tiktok.com/embed/v2/${url.split('/').pop()}`;
      iframeRef.current.src = embedUrl;
    }
  }, [url]);

  return (
    <Stack
      sx={{
        aspectRatio: { sm: '16/9', xs: '4/3' },
        ...(sx ?? {}),
      }}
    >
      <RenderField fieldId="title_t" model={model} />
      <iframe allowFullScreen frameBorder="0" height="100%" ref={iframeRef} title={title} width="100%" />
      <RenderField fieldId="description_t" model={model} />
    </Stack>
  );
};
