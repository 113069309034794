import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledWistiaVideoContainer = styled('div')(() => ({
  padding: '56.25% 0 0 0',
  position: 'relative',
}));

export const StyledWistiaVideoWrapper = styled(Box)(() => ({
  '& iframe': {
    border: 0,
  },
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
}));
