'use client';

import { createContext } from 'react';

import { DEFAULT_CONTENT_TYPE_COMPONENT_MAP } from '~/client/components/default-content-type-map';
import { CMSContext } from '~/types/cms-context';

const NOT_INITIALIZED = 'not initialized';

export const DEFAULT_CRAFTER_CONTEXT: CMSContext = {
  baseUrl: NOT_INITIALIZED,
  client: {
    contentTypeMap: DEFAULT_CONTENT_TYPE_COMPONENT_MAP,
    imageBreakpoints: [1536, 1280, 960, 720, 375],
  },
  getAccessToken: () => undefined,
  getPreviewToken: () => undefined,
  isSiteRequired: true,
  site: NOT_INITIALIZED,
  xb: {
    isAuthoring: false,
    isHeadlessMode: true,
  },
};

export const InternalCMSContext = createContext(DEFAULT_CRAFTER_CONTEXT);
