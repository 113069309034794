import { RenderField } from '@craftercms/experience-builder/react';
import { Stack, SxProps } from '@mui/material';
import { useEffect } from 'react';

import { CrafterFacebookVideo } from '~/types/models/facebook-video';

export type FacebookVideoProps = {
  model: CrafterFacebookVideo;
  sx?: SxProps;
};

export const FacebookVideo = ({ model, sx }: FacebookVideoProps): React.ReactNode => {
  const { url_t: url } = model;

  useEffect(() => {
    if (!window.FB) {
      ((d: Document, s: string, id: string): void => {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s) as HTMLScriptElement;
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v21.0';
        fjs.parentNode?.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    } else {
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <Stack
      sx={{
        aspectRatio: { sm: '16/9', xs: '4/3' },
        ...(sx ?? {}),
      }}
    >
      <RenderField fieldId="title_t" model={model} />
      <div className="fb-video" data-href={url} data-testid="facebook-video" />
      <RenderField fieldId="description_t" model={model} />
    </Stack>
  );
};
