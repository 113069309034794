import { RenderField } from '@craftercms/experience-builder/react';
import { Stack, Typography } from '@mui/material';
import { MediaPlayer, MediaProvider, MediaProviderAdapter, PlayerSrc, Poster, Track } from '@vidstack/react';
import { DefaultAudioLayout, defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';
import React from 'react';

import { VideoProps } from './Video';
import './video.css';

type Props = {
  captionsSrc: string | null;
  description: string;
  videoSrc: PlayerSrc;
  posterSrc: string;
  onProviderChange?: (detail: MediaProviderAdapter | null) => void;
} & VideoProps;

export const VideoView = ({ description, model, captionsSrc, videoSrc, posterSrc, onProviderChange }: Props): React.ReactNode => {
  return (
    <Stack gap={0.5}>
      {/* {error && <p>An error has occurred: {error?.message}</p>} */}
      <MediaPlayer className="cms-video-player" load="visible" onProviderChange={onProviderChange} playsInline src={videoSrc} title={description}>
        <MediaProvider>
          {/* TODO: Add Alt text field for thumbnail in Crafter */}
          <Poster alt={model.alt_text_s ?? 'Video Poster'} className="vds-poster" src={posterSrc} />
          {captionsSrc && <Track default kind="captions" label="English" lang="en-US" src={captionsSrc} type="srt" />}
        </MediaProvider>
        <DefaultAudioLayout icons={defaultLayoutIcons} />
        <DefaultVideoLayout icons={defaultLayoutIcons} />
      </MediaPlayer>
      <RenderField component={Typography} componentProps={{ sx: { textAlign: 'center' }, variant: 'subtitle1' }} fieldId="description_t" model={model} />
    </Stack>
  );
};
